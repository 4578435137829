import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import { useTranslation } from "react-i18next";
import { UserContext } from "../providers/UserProvider";
import { TWS } from "../services/WebServices";
import ErrorDialog from "../components/ErrorDialog";
import PricingButtons from "../components/internet/PricingButtons";
import Confirmation from "../components/Confirmation";
import SelectedCard from "../components/internet/SelectedCard";
import SendLoading from "../components/flexy/SendLoading";
import InputComponent from "../components/InputComponent";
const Idoom4G = props => {
  const { t } = useTranslation();
  const [selectedoffervisible, setselectedoffervisible] = useState(false);
  const [cardNumber, setcardNumber] = useState("");
  const [cardPts, setcardPts] = useState(0);
  const [cardImg, setcardImg] = useState(null);
  const [dataloading, setdataloading] = useState(true);
  const [cardNumberloading, setcardNumberloading] = useState(false);
  const [cardFifty, setcardFifty] = useState(0);
  const [cardThousand, setcardThousand] = useState(0);
  const [cardThousandHalf, setcardThousandHalf] = useState(0);
  const [cardThreethousand, setcardThreethousand] = useState(0);
  const [cardtwoThousandhalf, setcardtwoThousandhalf] = useState(0);
  const [cardThreethousandhalf, setcardThreethousandhalf] = useState(0);
  const [selectedamount, setselectedamount] = useState(0);
  const [errordialogMessage, seterrordialogMessage] = useState("");
  const [errordialog, seterrordialog] = useState(false);
  const [OverlayVisible, setOverlayVisible] = useState(false);
  const [sendwith, setsendwith] = useState("");
  const [deviceId, setDeviceId] = useState(null);
  const [cardOneThousandTwoHundred, setcardOneThousandTwoHundred] = useState(0);

  const [smsresponse, setsmsresponse] = useState(false);
  const { user } = useContext(UserContext);

  /**
   * get device id
   */
  const asyncLocalStorage = {
    getItem: function(key) {
      return Promise.resolve().then(function() {
        return localStorage.getItem(key);
      });
    }
  };
  useEffect(() => {
    asyncLocalStorage.getItem("deviceId").then(deviceId => {
      if (deviceId === null) {
        TWS.onUnauthorized();
      } else {
        setDeviceId(JSON.parse(deviceId));
      }
    });
  }, []);

  /**
   *  get card number
   */

  function getcardNumber(amount) {
    TWS.getCardNumber(user.id, amount, "4G", user.token)
      .then(res => {
        setcardNumber(res.code);
        setcardPts(res.pts);
        setcardImg(res.image);
        setcardNumberloading(false);
        setselectedoffervisible(true);
      })
      .catch(() => {});
  }

  const handelBtnClick = amount => {
    if (
      user.activebalance[0].balance == 0 ||
      user.activebalance[0].balance < parseInt(amount)
    ) {
      seterrordialog(true);
      seterrordialogMessage("Solde insuffisant,merci de charger votre compte");
    } else {
      ButtonAlert(amount);
    }
  };

  /**
   * check cards
   */
  useEffect(() => {
    TWS.checkCards("4G", user.token)
      .then(res => {
        setcardFifty(res.fifty);
        setcardThousand(res.thousand);
        setcardThousandHalf(res.thousandhalf);
        setcardtwoThousandhalf(res.twothousandhalf);
        setcardThreethousandhalf(res.threethousandhalf);
        setcardOneThousandTwoHundred(res.OneThousandTwoHundred);
        setdataloading(false);
      })
      .catch(() => {});
  }, []);

  /**
   *
   * buy card
   */

  function buyCard() {
    setcardNumberloading(true);
    //setselectedamount(amount);
    getcardNumber(selectedamount);
  }
  const ButtonAlert = amount => {
    setselectedamount(amount);
    handleConfirmation(true);
  };
  const handelErrorDialog = value => {
    seterrordialog(value);
  };

  const handeloverlay = value => {
    setOverlayVisible(!OverlayVisible);
    setsendwith(value);
  };

  const handelsmsresponse = value => {
    setsmsresponse(value);
  };
  const [openconfimation, setopenconfimation] = useState(false);
  const handleConfirmation = value => {
    setopenconfimation(value);
  };
  return (
    <div className="wrapper">
      <Navbar active="home" props={props} data={user} />
      {/* main */}
      <div className="main-content pb-5 idoom-page">
        <div className="container">
          <h1 className="pagetitle_intern text-center">Idoom 4G</h1>
          <ErrorDialog
            errordialog={errordialog}
            handelerrordialog={handelErrorDialog}
            message={errordialogMessage}
          />
          <Confirmation
            handleConfirmation={handleConfirmation}
            openconfimation={openconfimation}
            msg={"Vous voulez acheter une carte internet ?"}
            method={buyCard}
          />
          <SendLoading loading={cardNumberloading} />

          <InputComponent
            handeloverlay={handeloverlay}
            visible={OverlayVisible}
            cardnumber={cardNumber}
            type={"4G"}
            handelsmsresponse={handelsmsresponse}
            deviceId={deviceId}
          />

          {selectedoffervisible ? (
            <div className="row justify-content-center">
              <SelectedCard
                cardnumber={cardNumber}
                amount={parseInt(selectedamount)}
                type={"4G"}
                link={
                  "https://paiement.algerietelecom.dz/index.php?p=voucher_internet&produit=4g"
                }
                pts={cardPts}
                handeloverlay={handeloverlay}
                smsresponse={smsresponse}
                cardImg={cardImg}
              />
            </div>
          ) : (
            <div className="row justify-content-center">
              <PricingButtons
                handelbtnclick={handelBtnClick}
                disabled={cardFifty == 0 ? true : false}
                available={cardFifty}
                amount={500}
                imgsource={"images/idoom4g_500da.png"}
                pts={null}
                 price={null}
              />

              <PricingButtons
                handelbtnclick={handelBtnClick}
                disabled={cardThousand == 0 ? true : false}
                available={cardThousand}
                amount={1000}
                imgsource={"images/idoom4g_1000da.png"}
                pts={null}
                price={null}
              />

              <PricingButtons
                  handelbtnclick={handelBtnClick}
                  disabled={cardOneThousandTwoHundred  == 0 ? true : false}
                  available={cardOneThousandTwoHundred}
                  amount={1200}
                  imgsource={"images/idoom4g_1200da.png"}
                  pts={null}
                  price={null}
                />

              <PricingButtons
                handelbtnclick={handelBtnClick}
                disabled={cardThousandHalf == 0 ? true : false}
                available={cardThousandHalf}
                amount={1500}
                imgsource={"images/idoom4g_1500da.png"}
                pts={null}
                price={null}
              />

              <PricingButtons
                handelbtnclick={handelBtnClick}
                disabled={cardtwoThousandhalf == 0 ? true : false}
                available={cardtwoThousandhalf}
                amount={2500}
                imgsource={"images/idoom4g_2500da.png"}
                pts={null}
                price={null}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Idoom4G;
