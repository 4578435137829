import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../providers/UserProvider";
import { TWS } from "../../services/WebServices";
import ErrorDialog from "../../components/ErrorDialog";
import PricingButtons from "../../components/internet/PricingButtons";
import Confirmation from "../../components/Confirmation";
import SelectedGameCard from "../../components/games/SelectedGameCard";
import SendLoading from "../../components/flexy/SendLoading";

const GamesCards = props => {
  const { t } = useTranslation();
  const { operator } = useParams();
  const [selectedoffervisible, setselectedoffervisible] = useState(false);
  const [cardNumber, setcardNumber] = useState("");
  const [cardPts, setcardPts] = useState(0);
  const [dataloading, setdataloading] = useState(true);
  const [cardNumberloading, setcardNumberloading] = useState(false);
  const [pts, setpts] = useState([0,0,0]);
  const [selectedamount, setselectedamount] = useState(0);
  const [errordialogMessage, seterrordialogMessage] = useState("");
  const [errordialog, seterrordialog] = useState(false);
  const [deviceId, setDeviceId] = useState(null);

  const [smsresponse, setsmsresponse] = useState(false);
  const { user } = useContext(UserContext);
  /**
   * Operator selection
   */
  const networkOperator = useRef(
    operator == 1 ? "PUBG" :"FREEFIRE"
  );
  /**
   * get device id
   */
  const asyncLocalStorage = {
    getItem: function(key) {
      return Promise.resolve().then(function() {
        return localStorage.getItem(key);
      });
    }
  };
  useEffect(() => {
    asyncLocalStorage.getItem("deviceId").then(deviceId => {
      if (deviceId === null) {
        TWS.onUnauthorized();
      } else {
        setDeviceId(JSON.parse(deviceId));
      }
    });
  }, []);

  /**
   *  get card number
   */

  function getCardNumber(pts) {

    TWS.getGameCardNumber(
      user.id,
      pts,
      networkOperator.current,
      user.token
    )
      .then(res => {
      
        setcardNumber(res.code);
        //setcardPts(res.pts);
        setcardNumberloading(false);
        setselectedoffervisible(true);
      })
      .catch(() => {});
  }
  /**
   * check cards
   */

    const [checkList, setcheckList] = useState([]);
    useEffect(() => {
    TWS.checkGameCards(networkOperator.current, user.token)
      .then(res => {
       
        setcheckList(res.data)
        setdataloading(false);
      })
      .catch(() => {});
  }, []);


// Function to find price for points 60
function findPriceForPoints(pts) {
    // Find the object with points 60
    const obj = checkList.find(item => item.points === pts);
    // If the object exists, return its price, otherwise return null
    return obj ? obj.price : 0;
}
// pts  = utc in pubg and diamands in freefire
  const handelBtnClick = pts => {
    const priceForPoints = findPriceForPoints(pts);

    if (
      user.activebalance[0].balance == 0 ||
      user.activebalance[0].balance < parseInt(priceForPoints)
    ) {
      seterrordialog(true);
      seterrordialogMessage("Solde insuffisant,merci de charger votre compte");
    } else {
      ButtonAlert(pts);
    }
  };

  /**
   *
   * buy card
   */

  function buyCard() {
    //setcardNumberloading(true);
    //setselectedamount(amount);
    getCardNumber(selectedamount);
  }

  // pts here represente utc and diamands
  const ButtonAlert = (pts) => {
  
    setselectedamount(pts);
   handleConfirmation(true);
  };
  const handelErrorDialog = value => {
    seterrordialog(value);
  };

  const [openconfimation, setopenconfimation] = useState(false);
  const handleConfirmation = value => {
    setopenconfimation(value);
  };

 /**
   *  get card number
   */
 const [rechargeUrl, setrechargeUrl] = useState(null);
 function getUrlRecharge() {
   TWS.checkGameUrlRecharge(operator)
     .then(res => {
       setrechargeUrl(res.url)

     })
     .catch(() => {
      
     });
 }


 useEffect(() => {
   getUrlRecharge();
 }, []);


  return (
    <div className="wrapper">
      <Navbar active="home" props={props} data={user} />
      {/* main */}
      <div className={operator == 1 ? "main-content pb-5 idoom-page games_page_pubg" : "main-content pb-5 idoom-page games_page_fire"}>
        <div className="container">
          <h1 className="pagetitle_intern text-center text-white">
            Cartes {networkOperator.current}
          </h1>
          <ErrorDialog
            errordialog={errordialog}
            handelerrordialog={handelErrorDialog}
            message={errordialogMessage}
          />
          <Confirmation
            handleConfirmation={handleConfirmation}
            openconfimation={openconfimation}
            msg={"Vous voulez acheter une carte de recharge ?"}
            method={buyCard}
          />
          <SendLoading loading={cardNumberloading} />

          {selectedoffervisible ? (
            <div className="row justify-content-center">
              <SelectedGameCard
                cardnumber={cardNumber}
                amount={parseInt(selectedamount)}
                type={networkOperator.current}
                rechargeURL={rechargeUrl}
               
              />
            </div>
          ) : (
            <div className="row justify-content-center">
               {operator == 1
                    ? (
              <div className="row justify-content-center">
               {checkList.length != 0 ? (
                <>
                <PricingButtons
                  handelbtnclick={handelBtnClick}
                  disabled={checkList[0].count === 0 ? true : false}
                  available={checkList[0].count}
                  amount={checkList[0].points}
                  imgsource={"/images/pubg1.jpg" }
                  pts={null}
                  price={checkList[0].price}
                />
                

                <PricingButtons
                  handelbtnclick={handelBtnClick}
                  disabled={checkList[1].count === 0 ? true : false}
                  available={checkList[1].count}
                  amount={checkList[1].points}
                  imgsource={"/images/pubg2.jpg"}
                  pts={null}
                  price={checkList[1].price}
                />
                <PricingButtons
                  handelbtnclick={handelBtnClick}
                  disabled={checkList[2].count === 0 ? true : false}
                  available={checkList[2].count}
                  amount={checkList[2].points}
                  imgsource={"/images/pubg3.jpg"}
                  pts={null}
                  price={checkList[2].price}
                />
                <PricingButtons
                  handelbtnclick={handelBtnClick}
                  disabled={checkList[3].count === 0 ? true : false}
                  available={checkList[3].count}
                  amount={checkList[3].points}
                  imgsource={"/images/pubg4.jpg"}
                  pts={null}
                  price={checkList[3].price}
                />
                </>
                ): null}
              </div>):(
                <div className="row justify-content-center">
                {checkList.length != 0 ? (
                  <>
                  <PricingButtons
                    handelbtnclick={handelBtnClick}
                    disabled={checkList[0].count === 0 ? true : false}
                    available={checkList[0].count}
                    amount={checkList[0].points}
                    imgsource={"/images/freefire1.png"}
                    pts={null}
                    price={checkList[0].price}
                  />
                  
  
                  <PricingButtons
                    handelbtnclick={handelBtnClick}
                    disabled={checkList[4].count === 0 ? true : false}
                    available={checkList[4].count}
                    amount={checkList[4].points}
                    imgsource={"/images/freefire2.png"}
                    pts={null}
                    price={checkList[4].price}
                  />
                  <PricingButtons
                    handelbtnclick={handelBtnClick}
                    disabled={checkList[5].count === 0 ? true : false}
                    available={checkList[5].count}
                    amount={checkList[5].points}
                    imgsource={"/images/freefire3.png"}
                    pts={null}
                    price={checkList[5].price}
                  />
                  <PricingButtons
                    handelbtnclick={handelBtnClick}
                    disabled={checkList[6].count === 0 ? true : false}
                    available={checkList[6].count}
                    amount={checkList[6].points}
                    imgsource={"/images/freefire4.png"}
                    pts={null}
                    price={checkList[6].price}
                  />
                  <PricingButtons
                    handelbtnclick={handelBtnClick}
                    disabled={checkList[7].count === 0 ? true : false}
                    available={checkList[7].count}
                    amount={checkList[7].points}
                    imgsource={"/images/freefire5.png"}
                    pts={null}
                    price={checkList[7].price}
                  />
                  <PricingButtons
                    handelbtnclick={handelBtnClick}
                    disabled={checkList[8].count === 0 ? true : false}
                    available={checkList[8].count}
                    amount={checkList[8].points}
                    imgsource={"/images/freefire7.png"}
                    pts={null}
                    price={checkList[8].price}
                  />
                  <PricingButtons
                    handelbtnclick={handelBtnClick}
                    disabled={checkList[9].count === 0 ? true : false}
                    available={checkList[9].count}
                    amount={checkList[9].points}
                    imgsource={"/images/freefire8.png"}
                    pts={null}
                    price={checkList[9].price}
                  />
                  <PricingButtons
                    handelbtnclick={handelBtnClick}
                    disabled={checkList[10].count === 0 ? true : false}
                    available={checkList[10].count}
                    amount={checkList[10].points}
                    imgsource={"/images/freefire6.png"}
                    pts={null}
                    price={checkList[10].price}
                  />
                  </>
                ):null}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GamesCards;
