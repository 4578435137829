import React, { useState, useEffect, useRef, useContext } from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import { useTranslation } from "react-i18next";



export default function SelectedGameCard({
  cardnumber,
  amount,
  type,
  rechargeURL 
}) {
  const { t } = useTranslation();
 

  return (
    <>
      <div className="card position-relative internet_card">
        <div className="card-body text-center ">
          {type == "PUBG" ? (
            <img
              src={"/images/pubg.jpg" }
            
              className="card_img"
            />
          ) : (
            <img
              
                src={"/images/freefire.jpg" }
              
              alt=""
              className="card_img"
            />
          )}
        </div>
      </div>
      <div className="text-center mt-4 ">
        <Typography className="game_code" variant="h4" gutterBottom>
          {cardnumber}
        </Typography>
      </div>
      
      
     
      <div className="text-center mt-4 ">
            <Link
            className="game_recharge_link"
            component="button"
            variant="body2"
            onClick={() => {
              window.open(rechargeURL, "_blank");
            }}
          >
            Rechargement
          </Link>
      </div>
    </>
  );
}
